'use strict'

interface ElementMoved {
  el: Element
  prev: Element | null
  next: Element | null
  parent: ParentNode | null
  newParent: Element
}

const elements: Record<string, ElementMoved> = {}

export default {
  move(id: string, el: Element, newParent: Element): ElementMoved | undefined {
    if (!id || !el || !newParent) {
      return
    }

    elements[id] = {
      el,
      prev: el.previousElementSibling,
      next: el.nextElementSibling,
      parent: el.parentNode,
      newParent,
    }

    newParent.appendChild(el)

    return elements[id]
  },

  restore(id: string): boolean | undefined {
    if (!id || !elements[id]) {
      return
    }

    const element = elements[id]

    if (!element || !element.parent) {
      return
    }

    if ((element.prev === null && element.next !== null) || (element.prev !== null && element.next !== null)) {
      element.parent.insertBefore(element.el, element.next)
    } else {
      element.parent.appendChild(element.el)
    }

    return true
  },
}
