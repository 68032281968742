'use strict'

import { isDesktop } from 'assets/core/js/common'
import * as LayerManager from 'assets/core/js/module/layerManager'
import ElementPropertiesManager from 'assets/core/js/module/elementPropertiesManager'
import MobileBottomPanel from '@campings-group/design-system/src/design/objects/mobile-bottom-panel/twig/assets'
import type { MobileBottomPanelConfig } from '@campings-group/design-system/src/design/objects/mobile-bottom-panel/twig/assets'

export type MobileBottomPanelType = MobileBottomPanel

export const Events = {
  MOBILE_BOTTOM_PANEL_VISIBLE: 'mobile.bottom.panel.visible',
  MOBILE_BOTTOM_PANEL_HIDDEN: 'mobile.bottom.panel.hidden',
  MOBILE_BOTTOM_PANEL_CLOSE: 'mobile.bottom.panel.close',
  MOBILE_BOTTOM_PANEL_OPENED: 'mobile.bottom.panel.opened',
  MOBILE_BOTTOM_PANEL_CLOSED: 'mobile.bottom.panel.closed',
}

export default (element: string | HTMLElement, config?: MobileBottomPanelConfig): MobileBottomPanel => {
  if (!config) {
    config = {}
  }

  config.isMobile = () => !isDesktop()

  let onOpen: MobileBottomPanelConfig['onOpen'] | null = null
  let onClose: MobileBottomPanelConfig['onClose'] | null = null

  if (config?.onOpen) {
    onOpen = config.onOpen
  }

  config.onOpen = (mobileBottomPanel) => {
    LayerManager.onOpen(mobileBottomPanel.getElement())

    document.body.dispatchEvent(new CustomEvent(Events.MOBILE_BOTTOM_PANEL_VISIBLE))
    mobileBottomPanel.getElement().dispatchEvent(new CustomEvent(Events.MOBILE_BOTTOM_PANEL_OPENED))

    onOpen && onOpen(mobileBottomPanel)
  }

  if (config?.onClose) {
    onClose = config.onClose
  }

  config.onClose = (mobileBottomPanel) => {
    LayerManager.onClose(mobileBottomPanel.getElement())

    document.body.dispatchEvent(new CustomEvent(Events.MOBILE_BOTTOM_PANEL_HIDDEN))
    mobileBottomPanel.getElement().dispatchEvent(new CustomEvent(Events.MOBILE_BOTTOM_PANEL_CLOSED))

    onClose && onClose(mobileBottomPanel)
  }

  const mobileBottomPanel = new MobileBottomPanel(element, config)

  mobileBottomPanel.getElement().addEventListener(Events.MOBILE_BOTTOM_PANEL_CLOSE, () => {
    mobileBottomPanel.close()
  })

  ElementPropertiesManager.addProperty<MobileBottomPanelType>(mobileBottomPanel.getElement(), 'mobileBottomPanel', mobileBottomPanel)

  return mobileBottomPanel
}
