'use strict'

type MapRecord = Record<string, unknown>

const map = new WeakMap<HTMLElement, MapRecord>()

export default {
  addProperty<T>(el: HTMLElement, property: string, value: T): void {
    let values = map.get(el)
    if (values === undefined) {
      values = {}
    }

    values[property] = value

    map.set(el, values)
  },

  getProperty<T>(el: HTMLElement, property: string): T | undefined {
    if (!map.has(el)) {
      return undefined
    }

    const values = map.get(el) as MapRecord

    if (!values || !values[property]) {
      return undefined
    }

    return values[property] as T
  },

  hasProperty(el: HTMLElement, property: string): boolean {
    if (!map.has(el)) {
      return false
    }

    const values = map.get(el) as MapRecord

    if (!values || !values[property]) {
      return false
    }

    return true
  },

  removeProperty(el: HTMLElement, property: string): void {
    if (!map.has(el)) {
      return undefined
    }

    const values = map.get(el)

    if (!values || !values[property]) {
      return undefined
    }

    delete values[property]

    map.set(el, values)
  },
}
