'use strict'

import { isMobile, isTablet } from 'assets/core/js/common'
import ElementMover from 'assets/core/js/module/elementMover'
import mobileBottomPanel, { Events as MobileBottomPanelEvents } from 'assets/core/js/module/mobileBottomPanel'
import SearchAutocomplete, { Events as SearchAutocompleteEvents } from 'assets/core/js/module/searchAutocomplete'

export default function (): SearchAutocomplete | void {
  const containerEl = document.getElementById('autocomplete-container')
  let mobileBottomPanelIsOpened = false

  if (!containerEl) {
    return
  }

  const fieldEl = containerEl.querySelector<HTMLInputElement>('.autocomplete-field')

  if (!fieldEl) {
    return
  }

  const mobileBottomPanelToggleEl = containerEl.querySelector<HTMLElement>('#autocomplete-container .dca-mobile-bottompanel__toggle')

  if (!mobileBottomPanelToggleEl) {
    return
  }

  mobileBottomPanel('#autocomplete-container .dca-mobile-bottompanel__toggle')

  const mobileBottomPanelEl = document.getElementById('autocomplete-mobile-bottompanel')
  const autocompleteMirrorContainerEl = containerEl.querySelector('.autocomplete-input-mirror')

  if (!mobileBottomPanelEl || !autocompleteMirrorContainerEl) {
    return
  }

  mobileBottomPanelEl.addEventListener(MobileBottomPanelEvents.MOBILE_BOTTOM_PANEL_CLOSED, () => {
    ElementMover.restore('autocomplete-input')
    mobileBottomPanelIsOpened = false
    autocomplete.close()
  })

  fieldEl.addEventListener('focus', () => {
    if ((isMobile() || isTablet()) && !mobileBottomPanelIsOpened) {
      fieldEl.blur()
      mobileBottomPanelToggleEl.click()
    }
  })

  mobileBottomPanelEl.addEventListener(MobileBottomPanelEvents.MOBILE_BOTTOM_PANEL_OPENED, () => {
    mobileBottomPanelIsOpened = true
    fieldEl.blur()

    setTimeout(() => {
      fieldEl.blur()
      ElementMover.move('autocomplete-input', fieldEl, autocompleteMirrorContainerEl)
      const fieldVal = fieldEl.value
      fieldEl.value = ''
      fieldEl.value = fieldVal
    }, 0)
  })

  const autocomplete = new SearchAutocomplete(containerEl)

  containerEl.addEventListener(SearchAutocompleteEvents.AUTOCOMPLETE_ITEM_SELECTED, () => {
    if (isMobile() || isTablet()) {
      autocomplete.close()
      mobileBottomPanelEl.dispatchEvent(new CustomEvent(MobileBottomPanelEvents.MOBILE_BOTTOM_PANEL_CLOSE))
    }
  })

  return autocomplete
}
