'use strict'

import Modal from 'assets/core/js/module/modal'
import SelectManager from 'assets/core/js/module/selectManager'

export default function (): void {
  if (document.getElementById('search-around-link')) {
    const modal = new Modal(document.getElementById('modal-search-around') as HTMLElement)

    document.querySelectorAll('.search__around-link').forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault()
        modal.open()
      })
    })

    document.getElementById('search-around-button')?.addEventListener('click', function () {
      const autocompleteFieldEl = document.getElementById('search_offers_autocomplete_autocomplete') as HTMLInputElement
      const searchAroundFieldEl = document.getElementById('search-around-value') as HTMLInputElement
      const searchOffersMaxDistanceEl = document.getElementById('search-offers-maxDistance') as HTMLSelectElement

      autocompleteFieldEl.value = searchAroundFieldEl.value
      searchOffersMaxDistanceEl.value = SelectManager.getValue('search_offers_maxDistance') as string
      // close modal before submitting or the select won't be part of the form when the form is submitted
      modal.close()
      document.getElementById('search_offers_search')?.click()
    })
  }
}
