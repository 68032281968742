'use strict'

export default function (): void {
  const focusBlockFn = function (this: Element): void {
    this.closest('.search-form__block')?.classList.add('dca-form__field--focused')
  }

  const unfocusBlockFn = function (this: Element): void {
    this.closest('.search-form__block')?.classList.remove('dca-form__field--focused')
  }

  const changeFn = function (el: HTMLInputElement | HTMLSelectElement): void {
    if (el.value === '') {
      el.closest('.search-form__block')?.classList.remove('dca-form__field--filled')
    } else {
      el.closest('.search-form__block')?.classList.add('dca-form__field--filled')
    }
  }

  const clearFieldFn = function (e: Event): void {
    e.preventDefault()
    e.stopPropagation()

    const target = e.target as Element

    const inputEl = target.parentNode?.querySelector('.search-form__field') as HTMLInputElement | HTMLSelectElement
    inputEl.value = ''

    const event = new CustomEvent('clear')
    inputEl.dispatchEvent(event)
  }

  document.querySelectorAll('.search-form__field').forEach(function (el) {
    el.addEventListener('showDropdown', focusBlockFn)

    el.addEventListener('hideDropdown', function (this: Element) {
      this.closest('.search-form__block')?.classList.remove('dca-form__field--focused')

      changeFn(el as HTMLInputElement | HTMLSelectElement)
    })

    el.addEventListener('clear', () => {
      changeFn(el as HTMLInputElement | HTMLSelectElement)
    })
  })

  document.querySelectorAll('.search-form__list-toggle').forEach(function (el) {
    el.addEventListener('showMenu', focusBlockFn)
    el.addEventListener('hideMenu', unfocusBlockFn)
  })

  document.querySelectorAll('.search-form__list-field').forEach(function (el) {
    el.addEventListener('click', function () {
      if (el.closest('.dca-list')?.querySelectorAll('.search-form__list-field:checked').length === 0) {
        el.closest('.search-form__block')?.classList.remove('dca-form__field--filled')
      } else {
        el.closest('.search-form__block')?.classList.add('dca-form__field--filled')
      }
    })
  })

  document.querySelectorAll('.search-form__field-wrapper .field__reset').forEach(function (el) {
    el.addEventListener('click', clearFieldFn)
    el.addEventListener('touchstart', clearFieldFn)
  })

  if (document.querySelector('.search__form-section form.search-form')) {
    document.querySelector('.search__form-section form.search-form')?.addEventListener('submit', function (this: Element) {
      const submitEl = this.querySelector('.search-form__submit')

      if (!submitEl?.classList.contains('submit--loading')) {
        setTimeout(() => {
          submitEl?.classList.add('submit--loading')
        }, 0)

        const submitInputEl = document.getElementById('search_offers_search')

        if (submitInputEl && submitInputEl.hasAttribute('data-loading-message')) {
          submitInputEl.innerText = submitInputEl.getAttribute('data-loading-message') as string
        }
      }
    })
  }
  window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
      // Hide the loader when the page is fetched from bfcache.
      const submitEl = document.body.querySelector('.search-form__submit.submit--loading')
      submitEl?.classList.remove('submit--loading')
    }
  })

  if (document.querySelector('.search__block') && document.querySelector('.header')) {
    const headerObserver = new IntersectionObserver(function (entries) {
      if (entries[0]?.isIntersecting) {
        document.querySelector('.search__block')?.classList.remove('search__block--sticky')
        document.querySelector('.search__block')?.dispatchEvent(new CustomEvent('search-block-static'))
      } else {
        document.querySelector('.search__block')?.classList.add('search__block--sticky')
        document.querySelector('.search__block')?.dispatchEvent(new CustomEvent('search-block-sticky'))
      }
    })

    headerObserver.observe(document.querySelector('.header') as Element)
  }
}
