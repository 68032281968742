import calendar from 'assets/themes/campings/js/module/searchForm/calendar'
import autocomplete from 'assets/themes/campings/js/module/searchForm/autocomplete'
import events from 'assets/themes/campings/js/module/searchForm/events'
import menus from 'assets/themes/campings/js/module/searchForm/menus'
import maxDistance from 'assets/themes/campings/js/module/searchForm/maxDistance'
import TravelerSelector from '@ui/Search/Form/Fields/TravelerSelector/component'
import type { TravelerValues } from '@ui/TravelerSelector/component'

const travelerSelector = (): void => {
  const input = document.getElementById('search_offers_capacity') as HTMLInputElement
  if (input === null) {
    return
  }

  const travelerSelectorValuesUpdate = (values: TravelerValues, display: string): void => {
    input.value = JSON.stringify(values)
    // Update the value in the search recap.
    const recap = document.getElementById('search-filters-recap-list')
    if (!recap) {
      return
    }

    const item = recap.querySelector('.dca-recap__item[data-type="traveler_selector"]')
    if (!item) {
      return
    }

    const value = item.querySelector('.dca-recap__item-value')
    if (!value) {
      return
    }

    value.innerHTML = display
    if (!item.classList.contains('dca-recap__item--selected')) {
      item.classList.add('dca-recap__item--selected')
    }
  }

  // Instantiate the component.
  const travelerSelector = TravelerSelector(
    'search_offers_capacity_traveler_selector',
    undefined,
    travelerSelectorValuesUpdate,
    (): void => {
      const parentBlock = document.getElementById('search_offers_capacity_traveler_selector')?.closest('.dca-form__field-block') ?? null
      if (parentBlock !== null) {
        parentBlock.classList.add('dca-form__field--focused')
      }
    },
    (): void => {
      const parentBlock = document.getElementById('search_offers_capacity_traveler_selector')?.closest('.dca-form__field-block') ?? null
      if (parentBlock !== null) {
        parentBlock.classList.remove('dca-form__field--focused')
      }
    }
  )

  // Initialize the component with the form value.
  if (travelerSelector !== null) {
    let decoded = null
    try {
      decoded = JSON.parse(input.value)
    } catch (exception) {}

    if (
      typeof decoded !== 'object' ||
      decoded === null ||
      !Object.prototype.hasOwnProperty.call(decoded, 'adult') ||
      !(typeof (decoded as Record<'adult', unknown>).adult === 'number') ||
      !Object.prototype.hasOwnProperty.call(decoded, 'child') ||
      !(typeof (decoded as Record<'child', unknown>).child === 'number') ||
      !Object.prototype.hasOwnProperty.call(decoded, 'baby') ||
      !(typeof (decoded as Record<'baby', unknown>).baby === 'number') ||
      !Object.prototype.hasOwnProperty.call(decoded, 'pet') ||
      !(typeof (decoded as Record<'pet', unknown>).pet === 'number')
    ) {
      return
    }
    travelerSelector.setValues(decoded as TravelerValues)
  }
}

document.addEventListener('DOMContentLoaded', function () {
  calendar()
  autocomplete()
  events()
  menus()
  maxDistance()
  travelerSelector()
})
