'use strict'

const map = new Map<HTMLElement, number>()

export const onOpen = (el: HTMLElement): void => {
  if (map.has(el)) {
    return
  }

  const value = 9999999 + map.size + 1
  el.style.zIndex = value.toString()
  map.set(el, value)
}

export const onClose = (el: HTMLElement): void => {
  if (!map.has(el)) {
    return
  }

  el.style.zIndex = ''
  map.delete(el)
}
