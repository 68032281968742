'use strict'

/** @param {MouseEvent} e */
const changeTabs = (e: MouseEvent): void => {
  const targetEl = e.target as HTMLElement
  const tabsEl = targetEl.closest('.dca-tabs')
  const parentEl = targetEl.closest('[role="tablist"]')

  if (!tabsEl || !parentEl) {
    return
  }

  parentEl.querySelectorAll('[aria-selected="true"]').forEach((t) => t.setAttribute('aria-selected', 'false'))
  targetEl.setAttribute('aria-selected', 'true')
  tabsEl.querySelectorAll('[role="tabpanel"]').forEach((p) => p.setAttribute('hidden', 'true'))
  tabsEl.parentNode?.querySelector(`#${targetEl.getAttribute('aria-controls') as string}`)?.removeAttribute('hidden')

  parentEl.scrollLeft = targetEl.offsetLeft
}

export default (): void => {
  const tabs: HTMLElement[] = Array.from(document.querySelectorAll('.dca-tabs [role="tab"]'))
  const tabList = document.querySelector('.dca-tabs [role="tablist"]')

  if (!tabList) {
    return
  }

  tabs.forEach((tab) => {
    tab.addEventListener('click', changeTabs)
  })

  let tabFocus = 0

  tabList.addEventListener('keydown', (e) => {
    const event = e as KeyboardEvent

    if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
      tabs[tabFocus]?.setAttribute('tabindex', '-1')

      if (event.key === 'ArrowRight') {
        tabFocus++
        if (tabFocus >= tabs.length) {
          tabFocus = 0
        }
      }

      if (event.key === 'ArrowLeft') {
        tabFocus--
        if (tabFocus < 0) {
          tabFocus = tabs.length - 1
        }
      }

      tabs[tabFocus]?.setAttribute('tabindex', '0')
      tabs[tabFocus]?.focus()
    }
  })
}
