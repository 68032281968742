import Dropdown from '@ui/Form/Dropdown/component'
import TravelerSelector, { TravelerSelector as TravelerSelectorType, TravelerValues } from '@ui/TravelerSelector/component'

export type CallbackClickUpdateValues = () => void

const updateInputValues = (inputEl: HTMLInputElement, values: TravelerValues, triggerChangeEvent = true): void => {
  const pets = values.pet as number
  const persons = Object.keys(values)
    .filter((k) => k !== 'pet')
    .reduce((a, b) => a + (values[b] as number), 0)
  const petsText = inputEl.getAttribute('data-text-pets')?.split('|') as string[]
  const personsText = inputEl.getAttribute('data-text-persons')?.split('|') as string[]
  const text = inputEl.getAttribute('data-text')

  if (!text || !petsText || !personsText) {
    return
  }

  let inputValue = text.replace(
    '%pets%',
    pets > 1 ? (petsText[1] as string).replace('%count%', pets.toString()) : (petsText[0] as string).replace('%count%', pets.toString())
  )
  inputValue = inputValue.replace(
    '%persons%',
    persons > 1
      ? (personsText[1] as string).replace('%count%', persons.toString())
      : (personsText[0] as string).replace('%count%', persons.toString())
  )

  inputEl.value = inputValue
  triggerChangeEvent && inputEl.dispatchEvent(new Event('change', { bubbles: true }))
}

export default (
  fieldId: string,
  callbackClick?: (updateValues: CallbackClickUpdateValues) => void,
  onValuesUpdated?: (values: TravelerValues, display: string) => void,
  onOpen?: () => void,
  onClose?: () => void
): null | TravelerSelectorType => {
  const inputEl = document.getElementById(fieldId) as HTMLInputElement
  const dropdownEl = document.getElementById(`${fieldId}-dropdown`) as HTMLElement

  if (!inputEl || !dropdownEl) {
    return null
  }

  Dropdown(`${fieldId}-dropdown`, inputEl, dropdownEl, onOpen, onClose)

  const travelerSelectorEl = document.getElementById(`${fieldId}-travelerselector`)

  if (!travelerSelectorEl) {
    return null
  }

  return TravelerSelector(
    travelerSelectorEl,
    (values) => {
      if (callbackClick) {
        callbackClick(() => {
          updateInputValues(inputEl, values)
          onValuesUpdated && onValuesUpdated(values, inputEl.value)
        })
      } else {
        updateInputValues(inputEl, values)
        onValuesUpdated && onValuesUpdated(values, inputEl.value)
      }
    },
    (values) => {
      updateInputValues(inputEl, values, false)
      onValuesUpdated && onValuesUpdated(values, inputEl.value)
    }
  )
}
