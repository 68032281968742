'use strict'

import ExpandableMenu from 'assets/core/js/module/expandableMenu'

export default function (): ExpandableMenu[] {
  const expandableMenus: ExpandableMenu[] = []

  document.querySelectorAll('.search__form-section .dca-expandable__menu').forEach(function (el) {
    expandableMenus.push(new ExpandableMenu(el))
  })

  return expandableMenus
}
