import MobileBottomPanel from '@ui/MobileBottomPanel/component'

export default (id: string, triggerEl: HTMLElement, dropdownEl: HTMLElement, onOpen?: () => void, onClose?: () => void): void => {
  if (!triggerEl || !dropdownEl) {
    return
  }

  const mobileBottomPanelEl = document.getElementById(`${id}-mobilebottompanel`)

  if (!mobileBottomPanelEl) {
    return
  }

  const mobileBottomPanel = MobileBottomPanel(mobileBottomPanelEl)

  let isDropdownOpened = false

  const closeDropdownFn = (e: Event): void => {
    const clickedEl = e.target as HTMLElement

    if (!dropdownEl.contains(clickedEl)) {
      mobileBottomPanel.close()

      if (isDropdownOpened) {
        isDropdownOpened = false
        dropdownEl.setAttribute('hidden', 'hidden')
        triggerEl.setAttribute('aria-expanded', 'false')
        document.body.removeEventListener('click', closeDropdownFn)
      }

      onClose && onClose()
    }
  }

  const openDropdownFn = (): void => {
    if (!isDropdownOpened) {
      mobileBottomPanel.open()

      isDropdownOpened = true
      dropdownEl.removeAttribute('hidden')
      triggerEl.setAttribute('aria-expanded', 'true')
      requestAnimationFrame(() => {
        document.body.addEventListener('click', closeDropdownFn)
      })

      onOpen && onOpen()
    }
  }

  triggerEl.addEventListener('click', openDropdownFn)
}
