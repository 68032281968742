'use strict'

import * as coreCommon from 'assets/core/js/common'
import tabs from 'assets/core/js/module/tabs'
import Autocomplete, { AutocompleteConfig, AutocompleteResultValue } from './autocomplete'

interface SearchAutocompleteResultValue extends AutocompleteResultValue {
  category?: string
  context?: Record<string, string>
}

interface SearchAutocompleteResult {
  data: SearchAutocompleteResultValue
}

interface SearchAutocompleteJsonData extends Omit<AutocompleteResultValue, 'id' | 'type'> {
  token: string
  detail: {
    id: number
    category: string
    source?: string
  }
}

export const Events = {
  AUTOCOMPLETE_SET_DATA: 'autocomplete.set.data',
  AUTOCOMPLETE_MODAL_OPENED: 'autocomplete.modal.opened',
  AUTOCOMPLETE_MODAL_CLOSED: 'autocomplete.modal.closed',
  AUTOCOMPLETE_ITEM_SELECTED: 'autocomplete.item.selected',
}

export default class SearchAutocomplete extends Autocomplete {
  element!: Element
  dataEl!: HTMLInputElement
  events!: {
    inputClick?(e?: MouseEvent | TouchEvent | Event): void
    inputKeyUp?(e?: KeyboardEvent | TouchEvent | Event): void
    inputInput?(): void
    searchInputKeyUp?(e?: KeyboardEvent | TouchEvent | Event): void
  }

  constructor(element: string | HTMLElement, userConfig?: AutocompleteConfig) {
    const config: Partial<AutocompleteConfig> = {
      callbackSelect: (value: SearchAutocompleteResultValue) => {
        if (value.type !== 'query') {
          this.dataEl.value = JSON.stringify(value)
        }

        if (value.type === 'query') {
          this.dataEl.value = ''
        }

        this.element.dispatchEvent(
          new CustomEvent(Events.AUTOCOMPLETE_ITEM_SELECTED, {
            detail: {
              item: value,
            },
          })
        )
      },
      callbackEmptyInput: () => {
        this.dataEl.value = ''
      },
      callbackPreRender: function (resultEl, value: SearchAutocompleteResultValue) {
        const innerHtml = resultEl.innerHTML
        resultEl.setAttribute('data-type', value.category || '')
        resultEl.setAttribute('data-value', value.name)
        resultEl.innerHTML = ''

        const productNameEl = document.createElement('div')
        productNameEl.className = 'autocomplete-result-name'

        if (value.type === 'query') {
          // @ts-ignore: context is always available
          productNameEl.innerHTML = value.context.text.replace(/".*"/, function (query: string) {
            return '<mark>' + query + '</mark>'
          })
          resultEl.appendChild(productNameEl)
        } else {
          productNameEl.innerHTML = innerHtml

          resultEl.appendChild(productNameEl)

          if (value.description) {
            const locationEl = document.createElement('div')
            locationEl.className = 'autocomplete-result-localisation'
            locationEl.innerHTML = value.description
            resultEl.appendChild(locationEl)
          }
        }

        return resultEl
      },
      callbackOpen: () => {
        document.body.classList.add('search-form__autocomplete--opened')

        this.element.querySelectorAll<HTMLElement>('[data-tabIndex]').forEach((el) => {
          el.setAttribute('tabIndex', el.getAttribute('data-tabIndex') as string)
        })
      },
      callbackClose: () => {
        const currentlyOpened = this.element.classList.contains('autocomplete-active')
        document.body.classList.remove('search-form__autocomplete--opened')
        this.element.classList.remove('autocomplete-active')

        this.element.querySelectorAll<HTMLElement>('[data-tabIndex]').forEach((el) => {
          el.setAttribute('tabIndex', '-1')
        })

        if (currentlyOpened) {
          this.element.dispatchEvent(new CustomEvent(Events.AUTOCOMPLETE_MODAL_CLOSED))
        }
      },
      closeIfMinLengthBelow: false,
    }

    super(element, Object.assign({}, config, userConfig))
    this.dataEl = document.getElementById('autocomplete-data') as HTMLInputElement
  }

  initEvents(): void {
    super.initEvents()

    const activateFn = (e: Event): void => {
      if (e.cancelable) {
        e.preventDefault()
      }

      const closed = !this.element.classList.contains('autocomplete-active')

      this.element.classList.add('autocomplete-active')
      document.body.classList.add('search-form__autocomplete--opened')

      if (coreCommon.isAndroid()) {
        setTimeout(() => {
          this.inputEl.focus()
          this.inputEl.click()

          // @ts-ignore
          this.events.inputClick()
        }, 250)
      } else {
        this.inputEl.focus()
        // @ts-ignore
        this.events.inputClick()
      }

      if (closed) {
        this.element.dispatchEvent(new CustomEvent(Events.AUTOCOMPLETE_MODAL_OPENED))
      }
    }

    const inputLabel = this.inputEl.closest('label')

    if (inputLabel) {
      // @ts-ignore
      inputLabel.addEventListener('touchstart', activateFn)
    }

    // @ts-ignore
    this.inputEl.addEventListener(Events.AUTOCOMPLETE_SET_DATA, (e: CustomEvent<SearchAutocompleteJsonData>) => {
      const { token, name, nameHighlight, description, detail } = e.detail

      this.dataEl.value = JSON.stringify({
        token,
        name,
        nameHighlight,
        description,
        detail,
      })

      this.inputEl.value = name
    })

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.inputEl.removeEventListener('touchstart', this.events.inputClick)
    // @ts-ignore
    this.inputEl.addEventListener('touchstart', activateFn)
    this.inputEl.addEventListener('focus', activateFn)

    this.events.searchInputKeyUp = (e: KeyboardEvent) => {
      const rows = this.resultsEl.getElementsByClassName('autocomplete-result')

      // arrow up key
      if (e.keyCode === 38 && this.selectedRow > -1 && this.resultsVisible) {
        // @ts-ignore
        rows[this.selectedRow--].classList.remove('autocomplete-result-focus')

        if (this.selectedRow > -1) {
          // @ts-ignore
          rows[this.selectedRow].classList.add('autocomplete-result-focus')

          // @ts-ignore
          this.inputEl.value = rows[this.selectedRow].getAttribute('data-value')
        }
        // arrow down key
      } else if (e.keyCode === 40 && this.selectedRow < rows.length - 1 && this.resultsVisible) {
        if (this.selectedRow > -1) {
          // @ts-ignore
          rows[this.selectedRow].classList.remove('autocomplete-result-focus')
        }

        // @ts-ignore
        rows[++this.selectedRow].classList.add('autocomplete-result-focus')
        // @ts-ignore
        this.inputEl.value = rows[this.selectedRow].getAttribute('data-value')
      } else {
        // @ts-ignore
        this.events.inputKeyUp(e)
      }
    }

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.inputEl.removeEventListener('keyup', this.events.inputKeyUp)
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.inputEl.addEventListener('keyup', this.events.searchInputKeyUp)
  }

  displayResults(data: SearchAutocompleteResult): void {
    this.resultsEl.innerHTML = ''
    this.resultsVisible = true

    // force order of keys
    const dataKeys = ['geo', 'product']
    let hasResults = false
    let numberOfColumns = 0

    const createResults = (value: AutocompleteResultValue, containerEl: Element): void => {
      let resultEl = document.createElement('div')
      resultEl.className = 'autocomplete-result'
      resultEl.innerHTML = value.nameHighlight

      resultEl.addEventListener('click', () => {
        this.inputEl.value = value.name
        this.selectedRow = -1

        if (this.config.callbackSelect) {
          this.config.callbackSelect(value)
        }

        this.close()
      })

      resultEl.addEventListener('mouseover', (e) => {
        const focusedEl = this.resultsEl.getElementsByClassName('autocomplete-result-focus')[0]
        if (focusedEl && e.target !== focusedEl) {
          focusedEl.classList.remove('autocomplete-result-focus')
        }

        resultEl.classList.add('autocomplete-result-focus')

        this.selectedRow = -1
      })

      if (this.config.callbackPreRender) {
        resultEl = this.config.callbackPreRender(resultEl, value)
      }

      containerEl.appendChild(resultEl)
    }

    const createContainer = (key: string): HTMLDivElement => {
      const containerEl = document.createElement('div')
      containerEl.className = `autocomplete-results-${key}`

      if (this.element.hasAttribute(`data-autocomplete-title-${key}`)) {
        const containerTitleEl = document.createElement('div')
        containerTitleEl.className = 'autocomplete-results-title'
        containerTitleEl.innerHTML = this.element.getAttribute(`data-autocomplete-title-${key}`) as string
        containerEl.appendChild(containerTitleEl)
      }

      return containerEl
    }

    const createTabsContainer = (key: string, isActive: boolean): HTMLDivElement => {
      const containerEl = document.createElement('div')
      containerEl.className = `autocomplete-results-list autocomplete-results-${key}`
      containerEl.id = `autocomplete-tab-${key}-content`
      containerEl.setAttribute('data-tabIndex', '0')
      containerEl.setAttribute('aria-labelledby', `autocomplete-tab-${key}`)
      containerEl.setAttribute('role', 'tabpanel')

      if (!isActive) {
        containerEl.setAttribute('hidden', 'hidden')
      }

      return containerEl
    }

    const createTab = (key: string, isActive: boolean, tabsEl: HTMLDivElement): void => {
      if (this.element.hasAttribute(`data-autocomplete-tab-${key}`)) {
        const tabTitleEl = document.createElement('button')
        tabTitleEl.className = 'autocomplete-results-tab'
        tabTitleEl.innerHTML = this.element.getAttribute(`data-autocomplete-tab-${key}`) as string
        tabTitleEl.setAttribute('data-autocomplete-tab', key)
        tabTitleEl.setAttribute('role', 'tab')
        tabTitleEl.setAttribute('aria-controls', `autocomplete-tab-${key}-content`)
        tabTitleEl.id = `autocomplete-tab-${key}`
        tabTitleEl.setAttribute('data-tabIndex', '-1')
        tabTitleEl.type = 'button'

        if (isActive) {
          tabTitleEl.setAttribute('aria-selected', 'true')
          tabTitleEl.setAttribute('data-tabIndex', '0')
        }

        tabsEl.appendChild(tabTitleEl)
      }
    }

    const wrapperEl = document.createElement('div')
    wrapperEl.className = 'autocomplete-results-lists dca-tabs'

    const tabsEl = document.createElement('div')
    tabsEl.className = 'autocomplete-results-tabs'
    tabsEl.setAttribute('role', 'tablist')
    wrapperEl.appendChild(tabsEl)

    dataKeys.forEach((key, keyIndex) => {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (data.data && data.data[key] && Array.isArray(data.data[key]) && data.data[key].length > 0) {
        if (!hasResults) {
          hasResults = true
        }

        const containerEl = createTabsContainer(key, keyIndex === 0)

        createTab(key, keyIndex === 0, tabsEl)

        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
        data.data[key].forEach((value: AutocompleteResultValue) => {
          createResults(value, containerEl)
        })

        numberOfColumns++

        if (containerEl) {
          wrapperEl.setAttribute('data-cols', numberOfColumns.toString())
          wrapperEl.appendChild(containerEl)
        }
      }
    })

    this.resultsEl.appendChild(wrapperEl)

    tabs()

    const firstTab = this.resultsEl.querySelector<HTMLElement>('.autocomplete-results-tab')

    if (firstTab) {
      firstTab.click()
    }

    // @ts-ignore
    if (data.data && data.data.meta) {
      const containerEl = createContainer('meta')

      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      createResults(data.data.meta, containerEl)
      this.resultsEl.appendChild(containerEl)
    }

    if (hasResults) {
      if (this.inputEl.value !== '') {
        this.open()
      }
    }
  }

  getQueryParameters(): Record<string, string> {
    return super.getQueryParameters()
  }
}
