'use strict'

import ElementPropertiesManager from 'assets/core/js/module/elementPropertiesManager'

const toggleButtons = (plusButtonEl: HTMLButtonElement, minusButtonEl: HTMLButtonElement, inputEl: HTMLInputElement): void => {
  const currentValue = inputEl.value
  const min = inputEl.getAttribute('min')
  const max = inputEl.getAttribute('max')

  if (max && currentValue === max) {
    enableButton(minusButtonEl)
    disableButton(plusButtonEl)
  } else if (min && currentValue === min) {
    disableButton(minusButtonEl)
    enableButton(plusButtonEl)
  } else if (min && currentValue > min) {
    enableButton(plusButtonEl)
    enableButton(minusButtonEl)
  } else if (max && currentValue < max) {
    enableButton(plusButtonEl)
    enableButton(minusButtonEl)
  }
}

const enableButton = (el: HTMLButtonElement): void => {
  el.removeAttribute('disabled')
}

const disableButton = (el: HTMLButtonElement): void => {
  el.setAttribute('disabled', 'disabled')
}

export interface TravelerValues {
  [key: string]: number
}

export interface TravelerSelector {
  setValues: (values: TravelerValues) => void
  getValues: () => TravelerValues
}

const setValues = (rootEl: HTMLElement, values: TravelerValues): void => {
  Object.keys(values).forEach((k) => {
    const value = values[k]
    const inputEl = rootEl.querySelector<HTMLInputElement>(`[data-traveler-id="${k}"]`)

    if (!inputEl || !value) {
      return
    }

    inputEl.value = value.toString()
    // don't trigger a normal change Event as it would trigger the linked callback
    inputEl.dispatchEvent(
      new CustomEvent('change', {
        detail: {
          triggerCallback: false,
        },
      })
    )
  })
}

export default (
  rootEl: HTMLElement,
  callbackClick?: (values: TravelerValues) => void,
  callbackValuesSet?: (values: TravelerValues) => void
): TravelerSelector => {
  const values: TravelerValues = {}

  rootEl.querySelectorAll<HTMLElement>('.traveler-selector').forEach((el) => {
    const plusButtonEl = el.querySelector<HTMLButtonElement>('button[data-action="plus"]')
    const minusButtonEl = el.querySelector<HTMLButtonElement>('button[data-action="minus"]')
    const inputEl = el.querySelector<HTMLInputElement>('input')

    if (!inputEl || !plusButtonEl || !minusButtonEl) {
      return
    }

    const id = inputEl.getAttribute('data-traveler-id') as string
    values[id] = parseInt(inputEl.value)

    const step = Number(inputEl.step)

    inputEl.addEventListener('change', (e) => {
      const isCustomEvent = e instanceof CustomEvent

      const inputMin = parseInt(inputEl.getAttribute('min') as string)
      const inputMax = parseInt(inputEl.getAttribute('max') as string)
      const inputValue = parseInt(inputEl.value)

      if (inputValue > inputMax) {
        inputEl.value = inputMax.toString()
      }

      if (inputValue < inputMin) {
        inputEl.value = inputMin.toString()
      }

      values[id] = parseInt(inputEl.value)

      if (!isCustomEvent || (isCustomEvent && (e.detail as { triggerCallback: boolean }).triggerCallback === true)) {
        callbackClick && callbackClick(values)
      }

      toggleButtons(plusButtonEl, minusButtonEl, inputEl)
    })

    plusButtonEl.addEventListener('click', () => {
      inputEl.stepUp(step)

      values[id] = parseInt(inputEl.value)

      callbackClick && callbackClick(values)
      toggleButtons(plusButtonEl, minusButtonEl, inputEl)
    })
    minusButtonEl.addEventListener('click', () => {
      inputEl.stepDown(step)

      values[id] = parseInt(inputEl.value)

      callbackClick && callbackClick(values)
      toggleButtons(plusButtonEl, minusButtonEl, inputEl)
    })
  })

  const travelerSelector = {
    setValues: (values: TravelerValues) => {
      setValues(rootEl, values)
      callbackValuesSet && callbackValuesSet(values)
    },
    getValues: () => values,
  }

  ElementPropertiesManager.addProperty<TravelerSelector>(rootEl, 'travelerSelector', travelerSelector)

  return travelerSelector
}
